import {
  GET_LIST_DATA,
  GET_LIST_DATA_SUCCESS,
  GET_LIST_DATA_ERROR,
  SET_FILTERS
} from "../actionTypes";
import axiosInstance from "helpers/AxiosInstance";
import modals from "helpers/modals";

const getInvoicesAction = () => ({
  type: GET_LIST_DATA
});

const getInvoicesSuccess = payload => ({
  type: GET_LIST_DATA_SUCCESS,
  payload
});

const getInvoicesError = payload => ({
  type: GET_LIST_DATA_ERROR,
  payload
});

export const setFilters = filters => ({
  type: SET_FILTERS,
  payload: filters
});

const hasFilters = filters => {
  return Object.keys(filters).some(key => filters[key] !== undefined);
};

export const setStatus = payload => async (dispatch, getStore) => {
  try {
    await axiosInstance.post("setinvoicestatus", payload);
    modals.success("Status was changed successfully");
  } catch (e) {
    modals.success("There was an error.");
  }
  dispatch(getInvoices(dispatch, getStore));
};
export const getInvoices = () => async (dispatch, getState) => {
  try {
    dispatch(getInvoicesAction());
    const { page, order, ...filters } = getState().invoices.filters;
    let params = null;
    if (!hasFilters(filters)) {
      params = { page, order };
    } else params = { ...filters, order };

    const response = await axiosInstance.get(`/invoices`, {
      params: params
    });

    let { data } = response;

    if (data.pagination.total_pages === 1) data.pagination = false;

    if (!data) {
      throw new Error("There was a problem on our end. Please try again.");
    }

    dispatch(getInvoicesSuccess(data));
  } catch (error) {
    dispatch(getInvoicesError(error));
    try {
      modals.error(error.error);
    } catch {
      modals.error("There was a problem on our end. Please try again.");
    }
  }
};

export const removeInvoice = id => async (dispatch, getState) => {
  try {
    await axiosInstance.delete(`/invoices/${id}`);
    await getInvoices()(dispatch, getState);
  } catch (error) {
    dispatch(getInvoicesError(error));
    try {
      modals.error(error.error);
    } catch {
      modals.error("There was a problem on our end. Please try again.");
    }
  }
};
