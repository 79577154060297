export const GET_FORM_DATA = "GET_FORM_DATA";
export const GET_FORM_DATA_SUCCESS = "GET_FORM_DATA_SUCCESS";
export const GET_FORM_DATA_ERROR = "GET_FORM_DATA_ERROR";

export const GET_LIST_DATA = "GET_LIST_DATA";
export const GET_LIST_DATA_SUCCESS = "GET_LIST_DATA_SUCCESS";
export const GET_LIST_DATA_ERROR = "GET_LIST_DATA_ERROR";

export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_ERROR = "GET_INVOICE_ERROR";

export const SET_CURRENT_INVOICE = "SET_CURRENT_INVOICE";
export const SET_SEARCH_STATE = "SET_SEARCH_STATE";
export const SET_FILTERS = "SET_FILTERS";
