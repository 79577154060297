import React from "react";
import { DefaultLayout, NoLayout } from "layouts/index";
import LoginOverview from "containers/LoginOverview";
import { Redirect } from "react-router-dom";

import InvoiceTable from "containers/Invoices/InvoiceTable";
import InvoiceForm from "containers/Invoices/InvoiceForm";
import ClientForm from "containers/Clients/ClientForm";
import ClientTable from "containers/Clients/ClientTable";
import StaffTable from "containers/Staff/StaffTable";
import StaffForm from "containers/Staff/StaffForm";
import InviteForm from "containers/Staff/InviteForm";

export default [
  {
    path: "/",
    exact: true,
    layout: NoLayout,
    component: () => <Redirect to="/login" />
  },
  {
    path: "/login",
    layout: NoLayout,
    component: LoginOverview
  },
  {
    path: "/clients",
    exact: true,
    layout: DefaultLayout,
    component: ClientTable
  },
  {
    path: "/invoices",
    exact: true,
    layout: DefaultLayout,
    component: InvoiceTable
  },
  {
    path: "/staff",
    exact: true,
    layout: DefaultLayout,
    component: StaffTable
  },
  {
    path: "/invoices/:actionOrId?",
    exact: false,
    layout: DefaultLayout,
    component: InvoiceForm
  },
  {
    path: "/clients/:actionOrId?",
    layout: DefaultLayout,
    component: ClientForm
  },
  {
    path: "/staff/invite",
    layout: DefaultLayout,
    component: InviteForm
  },
  {
    path: "/staff/:id?",
    layout: DefaultLayout,
    component: StaffForm
  }
];
