import React from "react";
import { Row, Col, Form, Input, InputNumber, Icon } from "antd";
import numeral from "numeral";

class PaymentRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      qty: props.elem.qty,
      unit_price: props.elem.unit_price
    };
  }
  componentDidUpdate(prevProps) {
    const { elem } = this.props;
    if (
      elem.qty !== prevProps.elem.qty ||
      elem.unit_price !== prevProps.elem.unit_price
    ) {
      this.setState({
        qty: elem.qty,
        unit_price: elem.unit_price
      });
    }
  }
  handleNumericChange = (name, value) => {
    const { countTotal } = this.props;

    let state = { ...this.state };
    state[name] = value;
    this.setState(state, () => countTotal());
  };

  render() {
    const { elem, form, index } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Row gutter={10} key={`paymentWrap`}>
        <Col span={10} sm={8} md={12}>
          <Form.Item
            label={index === 0 && "Description"}
            key={`description-${index}`}
            required={true}
          >
            {getFieldDecorator(`description-${index}`, {
              initialValue: elem.description,
              rules: [{ required: true, message: "Description is required" }]
            })(<Input placeholder="Description" />)}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label={index === 0 && "Quantity"} key={`qty-${index}`}>
            {getFieldDecorator(`qty-${index}`, {
              initialValue: elem.qty,
              rules: [{ required: true, message: "Quantity is required" }]
            })(
              <InputNumber
                className="w-100"
                placeholder="Quantity"
                onChange={value => this.handleNumericChange("qty", value)}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label={index === 0 && "Unit Price"}
            key={`unit_price-${index}`}
          >
            {getFieldDecorator(`unit_price-${index}`, {
              initialValue: elem.unit_price,

              rules: [{ required: true, message: "Quantity is required" }]
            })(
              <InputNumber
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                onChange={value =>
                  this.handleNumericChange("unit_price", value)
                }
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                className="w-100"
                placeholder="Price"
              />
            )}
          </Form.Item>
        </Col>
        <Col span={6} sm={8} md={4}>
          <Form.Item label={index === 0 && "Total"}>
            <div className="form__control-row">
              <span className="form__total">
                {numeral(
                  Math.round(this.state.qty * this.state.unit_price * 10) / 10
                ).format("0,0.00")}
              </span>
              <button
                type="button"
                className="form__remove-row"
                onClick={() => this.props.removeRow(index)}
              >
                <Icon className="dynamic-delete-button" type="minus-circle-o" />
              </button>
            </div>
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

export default PaymentRow;
