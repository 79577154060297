import { connect } from "react-redux";
import {
  setCurrentClient,
  createClient,
  updateClient,
  fetchClientInfo,
  fetchClientFormState,
  setClientFormErrors,
  setPrimaryPersonsErrors
} from "store/clients/actions";

import ClientForm from "components/clients/ClientForm";

const mapStateToProps = ({ clients }) => ({
  currentClient: clients.currentClient,

  formState: clients.formState,
  formErrors: clients.formError,
  primaryPersonErrors: clients.primaryPersonErrors,
  loading: clients.isFetching
});
const mapDispatchToProps = {
  create: createClient,
  update: updateClient,
  setCurrentClient,
  setClientFormErrors,
  fetchClientInfo,
  fetchClientFormState,
  setPrimaryPersonsErrors
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientForm);
