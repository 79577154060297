export const FETCHING_STATE_ON = 'FETCHING_STATE_ON';
export const FETCHING_STATE_OFF = 'FETCHING_STATE_OFF';

export const STAFF_FETCH_SUCCESS = 'STAFF_FETCH_SUCCESS';
export const STAFF_FETCH_FAILURE = 'STAFF_FETCH_FAILURE';

export const SET_CURRENT_STAFF = 'SET_CURRENT_STAFF';

export const STAFF_SINGLE_FETCH_SUCCESS = 'STAFF_SINGLE_FETCH_SUCCESS';
export const STAFF_SINGLE_FETCH_FAILURE = 'STAFF_SINGLE_FETCH_FAILURE';
