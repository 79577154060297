import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { logout } from "store/clients/actions";
import { NavbarBrand } from "shards-react";
import SidebarNavItems from "../MainSidebar/SidebarNavItems";
import "./MainNavbar.css";
import { setRole } from "store/user/actions";

class MainNavbar extends React.Component {
  componentDidMount() {
    //const { setRole } = this.props;
    //setRole(localStorage.getItem("role"));
  }
  render() {
    const { stickyTop } = this.props;
    const classes = classNames(
      "main-navbar",
      "main-navbar_mobile",
      "bg-white",
      stickyTop && "sticky-top"
    );
    const { logout, role } = this.props;
    const Role = role || "manager";

    return (
      <div className={classes}>
        <NavbarBrand>
          <div className="d-table m-auto">
            <span className="d-md-inline">Joseph</span>
          </div>
        </NavbarBrand>
        <SidebarNavItems logout={logout} role={Role} />
      </div>
    );
  }
}

MainNavbar.propTypes = {
  layout: PropTypes.string,
  stickyTop: PropTypes.bool
};

MainNavbar.defaultProps = {
  stickyTop: true
};

export default connect(
  state => ({
    role: state.user.role
  }),
  {
    logout: logout,
    setRole: setRole
  }
)(MainNavbar);
