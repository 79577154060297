import React, { Component } from "react";
import { Select } from "antd";
import { connect } from "react-redux";

const Option = Select.Option;
export class StatusCell extends Component {
  state = {
    editMode: false
  };
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  _isMounted = false;
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this._isMounted = true;
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    this._isMounted = false;
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      let isOptionClicked =
        event.target.className ===
        "ant-select-dropdown-menu-item ant-select-dropdown-menu-item-active";

      if (this._isMounted && !isOptionClicked)
        this.setState({ editMode: false });
    }
  }
  toggleMode = () => this.setState({ editMode: !this.state.editMode });
  render() {
    const { record, statuses = [], setStatus } = this.props;
    const { editMode } = this.state;

    if (!editMode)
      return (
        <span
          onClick={this.toggleMode}
          style={{ cursor: "pointer" }}
          className="nowrap-text"
        >
          {record.status}
        </span>
      );

    return (
      <div ref={ref => (this.wrapperRef = ref)}>
        <Select
          defaultValue={record.status}
          onChange={value => {
            setStatus({ id: record.id, status_id: value });
            this.setState({ editMode: false });
          }}
        >
          {statuses.map(status => (
            <Option key={status.id}>{status.name}</Option>
          ))}
        </Select>
      </div>
    );
  }
}
export default connect(
  state => ({
    statuses: state.invoices.formData.status
  }),
  null
)(StatusCell);
