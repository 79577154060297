export const DATE_FORMAT = "YYYY-MM-DD";
export const LS_TOKEN_KEY = "JosephToken";
export const MAX_FILE_SIZE = 100000000;
export const validateFile = file => {
  return true;
};

export const PRIMARY_PERSON_INIT_STATE = {
  email: "",
  first_name: "",
  last_name: ""
};
export const getClientInitState = () => {
  let pp = { ...PRIMARY_PERSON_INIT_STATE };
  let persons = [];
  persons.push(pp);
  let cis = { ...CLIENT_INIT_STATE, primary_persons: persons };
  return cis;
};
export const CLIENT_INIT_STATE = {
  address: "",
  city: "",
  country: "",
  currency_id: 1,
  name: "",
  payment_type_id: 1,
  primary_persons: [{ ...PRIMARY_PERSON_INIT_STATE }],
  state: "",
  tax_id: 1,
  zip: ""
};

export const getNavBarItems = role => {
  let copy = [...NAV_BAR_ITEMS];
  if (role === "admin") {
    copy = copy.concat({
      title: "Staff",
      htmlBefore: '<i class="material-icons">people</i>',
      to: "/staff"
    });
  }
  return copy;
};

export const NAV_BAR_ITEMS = [
  {
    title: "Clients",
    htmlBefore: '<i class="material-icons">contacts</i>',
    to: "/clients"
  },
  {
    title: "Invoices",
    htmlBefore: '<i class="material-icons">account_balance_wallet</i>',
    to: "/invoices"
  }
];
