export const options = {
  rules: {
    default: [
      {
        max: 255,
        message: "The length can't be more than 255 characters."
      }
    ],
    required: [
      {
        required: true,
        message: 'This field is required.'
      }
    ],
    email: [
      {
        type: 'email',
        message: 'Email is not valid.'
      }
    ],
    add: [
      {
        max: 500,
        message: "The length can't be more than 500 characters."
      }
    ]
  }
};
