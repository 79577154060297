import React from 'react';
import { Row, Col, Input, Form, Button, Spin } from 'antd';
import { Card, CardBody, Container } from 'shards-react';
import PageTitle from 'components/common/PageTitle';
import { history } from 'helpers/history';
import { options } from 'helpers/validationRules';
import { staffFormItemLayout, staffBtnLayout } from 'helpers/formLayout';
import './InviteForm.css';
class InviteForm extends React.Component {
  render() {
    const { getFieldDecorator, validateFields, getFieldValue } = this.props.form;
    const { create } = this.props;
    return (
      <Container fluid className="main-content-container">
        <Row type="flex" justify="center">
          <Col span={24} sm={20} md={16} lg={12} xl={10}>
            <Row type="flex" justify="space-between" className="page-header">
              <PageTitle title="Invite a manager" className="mr-sm-auto" />
              <Button
                type="secondary"
                name="goback"
                onClick={() => {
                  history.push('/staff');
                }}
              >
                Go Back
              </Button>
            </Row>
            <Spin spinning={false}>
              <Card small className="mb-4">
                <CardBody className="p-3">
                  <Form
                    className="invite-form"
                    onSubmit={event => {
                      event.preventDefault();
                      validateFields('email', {}, (errors, values) => {
                        if (errors) return;
                        create(getFieldValue('email'));
                      });
                    }}
                  >
                    <Form.Item label="E-mail" {...staffFormItemLayout}>
                      {getFieldDecorator('email', {
                        validateTrigger: 'onBlur',
                        rules: [
                          ...options.rules.default,
                          ...options.rules.email,
                          ...options.rules.required
                        ]
                      })(<Input placeholder="E-mail" />)}
                    </Form.Item>

                    <Form.Item {...staffBtnLayout} className="invite-form__btn">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn-primary add-client__btn"
                      >
                        Invite
                      </Button>
                    </Form.Item>
                  </Form>
                </CardBody>
              </Card>
            </Spin>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Form.create({ name: 'invite-form' })(InviteForm);
