import { connect } from "react-redux";
import actions from "store/invoices/actions";
import InvoiceTable from "components/invoices/InvoiceTable";
import { withRouter } from "react-router-dom";
import { setStatus } from "store/invoices/actions/list";
const { getInvoices, removeInvoice, setFilters, getFormData } = actions;

const mapStateToProps = ({ invoices }) => {
  return {
    data: invoices.invoices,
    rates: invoices.rates,
    pagination: invoices.pagination,
    isConverted: invoices.isConverted,
    totalConverted: invoices.totalConverted,
    filters: invoices.filters,
    formData: invoices.formData,
    loading: invoices.loading
  };
};

const mapDispatchToProps = {
  getInvoices,
  removeInvoice,
  setStatus,
  setFilters,
  getFormData
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvoiceTable)
);
