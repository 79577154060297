import React from "react";
import { Form, Button } from "antd";
import PaymentRow from "./PaymentRow/index";

class PaymentRowContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.rowRefs = [];
  }
  componentDidUpdate(prevProps) {
    const { countTotal } = this.props;
    if (!prevProps.current.payments || !this.props.current.payments) return;
    if (
      prevProps.current.payments.length !== this.props.current.payments.length
    ) {
      setTimeout(countTotal, 600);
    }
  }
  addNewRow = () => {
    const { setCurrentInvoice, current } = this.props;
    const copy = [...current.payments];
    copy.push({ description: "", qty: 0, unit_price: 0 });
    setCurrentInvoice({ ...current, payments: copy });
  };

  removeRow = index => {
    const { setCurrentInvoice, current, form } = this.props;
    const { getFieldValue, setFieldsValue } = form;
    const values = getFieldValue("payments");

    setFieldsValue(
      { payments: values.filter((val, idx) => idx !== index) },
      () => {
        setCurrentInvoice({
          ...current,
          payments: current.payments.filter((_e, ind) => ind !== index)
        });
      }
    );
  };
  render() {
    const { current, form, countTotal } = this.props;
    const { getFieldDecorator } = form;

    getFieldDecorator("payments", { initialValue: current.payments });
    const Payments = current.payments || [];
    const formItems = Payments.map((elem, index) => (
      <PaymentRow
        countTotal={countTotal}
        form={form}
        key={`pr-${index}`}
        wrappedComponentRef={ref => this.rowRefs.push(ref)}
        removeRow={this.removeRow}
        elem={elem}
        index={index}
      />
    ));

    return (
      <div className="payments form-item-group">
        <div className="payments-inner">{formItems}</div>
        <Button
          type="dashed"
          className="add-new-line-btn"
          onClick={() => this.addNewRow()}
        >
          + add a new line
        </Button>
      </div>
    );
  }
}

export default Form.create()(PaymentRowContainer);
