import React from "react";
import { Col } from "antd";

import numeral from "numeral";
import "./Total.css";

export default class Total extends React.Component {
  componentDidMount() {
    const { countTotal } = this.props;
    countTotal();
  }
  componentDidUpdate(prevProps, prevState) {
    const { countTotal } = this.props;
    if (prevProps.subtotal !== this.props.subtotal) {
      countTotal();
    }
  }

  render() {
    const { subtotal, taxes } = this.props;

    const tax = taxes.find(el => el.id === this.props.tax) || 0;
    const total = subtotal * (1 + (tax.percent || 0) / 100);
    return (
      <Col
        span={24}
        sm={12}
        md={10}
        xl={8}
        className="total-col"
        style={{ width: "100%" }}
      >
        <div className="total">
          <div className="total__row total__text">
            Subtotal
            <span>{numeral(subtotal).format("0,0.00")}</span>
          </div>
          <div className="total__row total__text">
            Tax
            <span>{tax.percent || 0}%</span>
          </div>
          <div className="total__row total__text total__text_lg">
            Total
            <span>{numeral(total).format("0,0.00")}</span>
          </div>
        </div>
      </Col>
    );
  }
}
