import React from "react";
import { GoogleLogin } from "react-google-login";
import { Redirect } from "react-router-dom";
import { message } from "antd";

export default props => {
  const {
    REACT_APP_GOOGLE_CLIENT_ID,
    REACT_APP_GOOGLE_REDIRECT_URL
  } = process.env;
  const { performLogin } = props;
  const token = localStorage.getItem("token");

  if (token) return <Redirect to="/invoices" />;

  return (
    <GoogleLogin
      clientId={REACT_APP_GOOGLE_CLIENT_ID}
      buttonText="Login with Google"
      onSuccess={performLogin}
      onFailure={response => {
        message.config({
          top: 100,
          maxCount: 3
        });
        if (!response.Zi) {
          message.error("AuthFailed");
        } else if (!response.Zi.access_token) {
          message.error("This account is not appropriate. Try another one", 3);
        }
      }}
      responseType="id_token"
      redirectUri={REACT_APP_GOOGLE_REDIRECT_URL}
      render={renderProps => {
        return (
          <div className="login-wrap">
            <div className="login-img-wrap">
              <img
                className="login-img"
                src={require("images/logo_a2design_black.png")}
                alt="A2Design"
              />
            </div>
            <button
              id="loginButton"
              className="btn-login btn-outline-primary"
              onClick={renderProps.onClick}
            >
              <img
                className="btn-login-icon"
                src={require("images/google.png")}
                alt=""
              />
              Login with Google
            </button>
          </div>
        );
      }}
      cookiePolicy={"single_host_origin"}
    />
  );
};
