import { connect } from 'react-redux';
import StaffTable from 'components/staff/StaffTable';
import { getStaff, removeStaff } from 'store/staff/actions';

const mapStateToProps = ({ staff }) => ({
  data: staff.data,
  pagination: staff.pagination,
  loading: staff.isFetching
});
const mapDispatchToProps = {
  fetchStaff: getStaff,
  removeStaff: removeStaff
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StaffTable);
