import moment from "moment";

moment.locale("eu");

export default {
  getAuthData() {
    return {
      token: localStorage.getItem("token"),
      role: localStorage.getItem("role")
    };
  },

  setAuthData(data) {
    if (!data.token_type || !data.access_token) return;

    let token = `${data.token_type} ${data.access_token}`;
    let expiresIn = moment()
      .add(data.expires_in, "seconds")
      .format("YYYY-MM-DD HH:mm:ss");

    localStorage.setItem("token", token);
    localStorage.setItem("role", data.role);
    localStorage.setItem("expires_in", expiresIn);
  },

  clearAuthData() {
    localStorage.removeItem("token");
    localStorage.removeItem("expires_in");
  }
};
