import React from "react";
import { Input, Form, Button, Icon } from "antd";
import { tailFormItemLayout } from "helpers/formLayout";
import { options } from "helpers/validationRules";
import "./Primary.css";
class ClientPrimaryFields extends React.Component {
  render() {
    const { getFieldDecorator } = this.props.form;
    const { currentClient, removePerson, addPerson } = this.props;

    getFieldDecorator("persons", { initialValue: [] });
    const persons = currentClient ? currentClient.primary_persons : [];
    const formItems = persons.map((elem, index) => (
      <div key={`primaryWrap-${index}`} className="primary-wrap">
        <div className="primary-inputs">
          <Form.Item
            label="Primary person"
            required={index === 0}
            key={`first_name-${index}`}
            className="primary-input"
          >
            {getFieldDecorator(`first_name-${index}`, {
              rules: [...options.rules.default, ...options.rules.required]
            })(<Input placeholder="First name" />)}
          </Form.Item>

          <Form.Item
            required={index === 0}
            key={`last_name-${index}`}
            className="primary-input"
          >
            {getFieldDecorator(`last_name-${index}`, {
              rules: [...options.rules.default, ...options.rules.required]
            })(<Input placeholder="Last name" />)}
          </Form.Item>
        </div>

        <Form.Item label="Email" required={index === 0} key={`email-${index}`}>
          {getFieldDecorator(`email-${index}`, {
            rules: [
              ...options.rules.default,
              ...options.rules.email,
              ...options.rules.required
            ]
          })(<Input placeholder="name@email.com" />)}
        </Form.Item>
        {persons.length > 1 && (
          <Icon
            className="dynamic-delete-button"
            type="minus-circle-o"
            onClick={() => removePerson(index)}
          />
        )}
      </div>
    ));

    return (
      <div className="primary-persons form-item-group">
        {formItems}
        <Form.Item {...tailFormItemLayout}>
          <Button type="dashed" className="add-person-btn" onClick={addPerson}>
            + add person
          </Button>
        </Form.Item>
      </div>
    );
  }
}

export default Form.create({
  name: "primary-fields",
  mapPropsToFields: props => {
    const { primaryPersonErrors, currentClient } = props;

    if (!currentClient) return {};
    const persons = [...currentClient.primary_persons];

    const Errors = primaryPersonErrors || {};

    let object = {};

    persons.forEach((el, index) => {
      Object.keys(el).forEach(key => {
        object[`${key}-${index}`] = Form.createFormField({
          ...Errors[`${key}-${index}`],
          value: persons[index][key]
        });
      });
    });
    return object;
  },
  onFieldsChange: (props, changed, all) => {
    const {
      primaryPersonErrors,
      setPrimaryPersonsErrors,
      currentClient,
      setCurrentClient
    } = props;
    const Errors = primaryPersonErrors || {};

    const key = Object.keys(changed)[0];
    const field = key.split("-")[0];
    const index = +key.split("-")[1];
    const persons = [...currentClient.primary_persons];
    persons[index][field] = changed[key].value;

    const personsError = { ...Errors, ...all };
    setPrimaryPersonsErrors({ ...personsError });

    setCurrentClient({
      ...currentClient,
      primary_persons: persons
    });
  }
})(ClientPrimaryFields);
