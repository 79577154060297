import React, { Component } from "react";
import { Table } from "antd";
import { invoiceTableColumns } from "./columns";
import { CardBody } from "shards-react";
import lodash from "lodash";
export default class TableWrapper extends Component {
  shouldComponentUpdate(newProps) {
    const { data, pagination } = this.props;
    const { data: newData, pagination: newPagination } = newProps;

    if (data === newData && lodash.isEqual(newPagination, pagination)) return false;
    return true;
  }

  render() {
    const { data, pagination, handleTableChange, removeInvoice, rates, setStatus } = this.props;
    return (
      <CardBody className="p-0">
        <Table
          dataSource={data}
          onChange={handleTableChange}
          rowKey={record => record.id || record._id}
          pagination={pagination}
          scroll={{ x: true }}
          columns={invoiceTableColumns(removeInvoice, rates, setStatus)}
        />
      </CardBody>
    );
  }
}
