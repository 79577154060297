import { connect } from 'react-redux';
import InviteForm from 'components/staff/InviteForm';
import { createStaff } from 'store/staff/actions';

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  create: createStaff
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteForm);
