import {
  CLIENT_FETCH_SUCCESS,
  SET_AS_CURRENT_CLIENT,
  FETCHING_STATE_ON,
  FETCHING_STATE_OFF,
  CLIENT_SINGLE_FETCH_SUCCESS,
  SENDING_STATE_ON,
  SENDING_STATE_OFF,
  SET_CLIENT_FORM_STATE,
  SET_FORM_ERRORS,
  SET_PRIMARY_PERSONS_ERRORS,
  SET_FILTERS
} from "./actionTypes";

const initialState = {
  currentClient: null,
  values: [],
  filters: {
    id: null
  },
  shortValues: [],
  primaryPersonErrors: {},
  formError: {
    persons: [{}]
  },
  formState: null,
  pagination: {
    per_page: 10,
    current_page: 1,
    total: 0
  },
  isFetching: false,
  isSending: false
};

const ClientsHandler = {
  [FETCHING_STATE_ON](state) {
    return { ...state, isFetching: true };
  },
  [FETCHING_STATE_OFF](state) {
    return { ...state, isFetching: false };
  },
  [SENDING_STATE_ON](state) {
    return { ...state, isSending: true };
  },
  [SENDING_STATE_OFF](state) {
    return { ...state, isSending: false };
  },
  [CLIENT_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      values: action.payload.data,
      pagination: action.payload.pagination,
      shortValues: action.payload.clients,
      isFetching: false
    };
  },
  [CLIENT_SINGLE_FETCH_SUCCESS](state, action) {
    action.payload.managers = action.payload.managers.map(m => m.id);
    return {
      ...state,
      currentClient: action.payload,
      isFetching: false
    };
  },
  [SET_FORM_ERRORS](state, action) {
    return { ...state, formError: action.payload };
  },
  [SET_PRIMARY_PERSONS_ERRORS](state, action) {
    return { ...state, primaryPersonErrors: action.payload };
  },
  [SET_CLIENT_FORM_STATE](state, action) {
    return {
      ...state,
      formState: action.payload,
      isFetching: false
    };
  },
  [SET_AS_CURRENT_CLIENT](state, action) {
    return { ...state, currentClient: action.payload };
  },
  [SET_FILTERS](state, action) {
    return { ...state, filters: action.payload };
  }
};

export default (state = initialState, action) =>
  ClientsHandler[action.type]
    ? ClientsHandler[action.type](state, action)
    : state;
