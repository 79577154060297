import React from "react";
import { Row, Col, Input, Form, Button, Spin, Select } from "antd";
import { Card, CardBody, Container } from "shards-react";
import { isNumeric } from "helpers/functions";
import PageTitle from "components/common/PageTitle";
import { history } from "helpers/history";
import { options } from "helpers/validationRules";
import "./ClientForm.css";

import ClientPrimaryFields from "./ClientPrimaryFields";
import {
  renderPaymentOptions,
  renderTaxOptions,
  renderCurrencyOptions
} from "../helpers";
import {
  noLabelLayout,
  tailFormItemLayout,
  formItemLayout
} from "helpers/formLayout";
import { PRIMARY_PERSON_INIT_STATE, getClientInitState } from "store/constants";
import axiosInstance from "helpers/AxiosInstance";

const InputGroup = Input.Group;
const { TextArea } = Input;
const Option = Select.Option;

class ClientForm extends React.Component {
  state = {
    managers: []
  };

  componentDidMount() {
    const {
      fetchClientInfo,
      fetchClientFormState,
      setCurrentClient,
      setClientFormErrors,
      setPrimaryPersonsErrors,
      formState,
      match: {
        params: { actionOrId }
      }
    } = this.props;

    !formState && fetchClientFormState();

    setCurrentClient({ primary_persons: [] });
    setClientFormErrors({ persons: [{}] });
    setPrimaryPersonsErrors({});
    this.searchManagers("");
    if (isNumeric(actionOrId)) {
      fetchClientInfo(actionOrId);
      return;
    }

    setCurrentClient(getClientInitState());
  }
  addPerson = () => {
    const { setCurrentClient, currentClient } = this.props;
    const pp = [...currentClient.primary_persons];
    pp.push({ ...PRIMARY_PERSON_INIT_STATE });
    setCurrentClient({
      ...currentClient,
      primary_persons: pp
    });
  };
  removePerson = id => {
    const { setCurrentClient, currentClient } = this.props;
    setCurrentClient({
      ...currentClient,
      primary_persons: currentClient.primary_persons.filter(
        (el, index) => index !== id
      )
    });
  };

  searchManagers = async query => {
    try {
      let managers = await axiosInstance.get(`/getmanager?q=${query}`);
      this.setState({ managers: managers.data });
    } catch (e) {
      console.warn("search fallback");
    }
  };

  render() {
    const {
      currentClient,
      match: {
        params: { actionOrId }
      },
      form,
      formState,
      update,
      create,
      loading,
      primaryPersonErrors,
      setCurrentClient,

      setPrimaryPersonsErrors
    } = this.props;
    const { getFieldDecorator } = form;
    const { tax, currency, payment_type } = formState || {};
    let taxes = tax || [];
    let currencies = currency || [];
    let paymentTypes = payment_type || [];

    return (
      <Container fluid className="main-content-container">
        <Row type="flex" justify="center">
          <Col span={24} xl={20} className="form-col client-form-col">
            <Row type="flex" justify="space-between" className="page-header">
              <PageTitle
                title={isNumeric(actionOrId) ? "Client info" : "Add new client"}
                className="mr-sm-auto"
              />
              <Button
                type="secondary"
                name="goback"
                onClick={() => {
                  history.push("/clients");
                }}
              >
                Go Back
              </Button>
            </Row>
            <Spin spinning={loading}>
              <Card small className="mb-4">
                <CardBody className="p-3">
                  <Form
                    className="client-form"
                    onSubmit={event => {
                      event.preventDefault();
                      let isError = false;
                      this.ref.validateFields(error => {
                        if (error) isError = true;
                      });

                      form.validateFields(errors => {
                        if (errors) isError = true;
                      });

                      if (!isError) {
                        if (isNumeric(actionOrId)) update(currentClient);
                        else create(currentClient);
                      }
                    }}
                  >
                    <Form.Item label="Business name">
                      {getFieldDecorator("name", {
                        rules: [
                          ...options.rules.default,
                          ...options.rules.required
                        ]
                      })(<Input placeholder="Business name" />)}
                    </Form.Item>

                    <ClientPrimaryFields
                      ref={ref => (this.ref = ref)}
                      primaryPersonErrors={primaryPersonErrors}
                      addPerson={this.addPerson}
                      removePerson={this.removePerson}
                      currentClient={currentClient}
                      setCurrentClient={setCurrentClient}
                      setPrimaryPersonsErrors={setPrimaryPersonsErrors}
                    />

                    <Form.Item label="Postal address">
                      {getFieldDecorator("attention", {
                        rules: options.rules.default
                      })(<Input placeholder="Attention" />)}
                    </Form.Item>

                    <Form.Item {...noLabelLayout}>
                      {getFieldDecorator("address", {
                        rules: options.rules.default
                      })(<TextArea placeholder="Address" rows="3" />)}
                    </Form.Item>

                    <Form.Item {...noLabelLayout}>
                      {getFieldDecorator("city", {
                        rules: options.rules.default
                      })(<Input placeholder="City / Town" />)}
                    </Form.Item>

                    <div className="address-inputs form-item-group">
                      <Form.Item>
                        {getFieldDecorator("state", {
                          rules: options.rules.default
                        })(<Input placeholder="State / Region" />)}
                      </Form.Item>
                      <Form.Item>
                        {getFieldDecorator("zip", {
                          rules: options.rules.zip
                        })(<Input placeholder="Postal / Zip code" />)}
                      </Form.Item>
                    </div>

                    <Form.Item {...noLabelLayout}>
                      {getFieldDecorator("country", {
                        rules: [
                          ...options.rules.default,
                          ...options.rules.required
                        ]
                      })(<Input placeholder="Country" />)}
                    </Form.Item>

                    <Form.Item label="Details" help={""} validateStatus={""}>
                      <InputGroup compact className="input-group-details">
                        {getFieldDecorator("tax_id")(
                          <Select style={{ width: "40%" }} placeholder="Tax">
                            {taxes.map(renderTaxOptions)}
                          </Select>
                        )}

                        {getFieldDecorator("currency_id")(
                          <Select
                            style={{ width: "20%" }}
                            placeholder="Currency"
                          >
                            {currencies.map(renderCurrencyOptions)}
                          </Select>
                        )}

                        {getFieldDecorator("payment_type_id")(
                          <Select
                            style={{ width: "40%" }}
                            placeholder="Payment method"
                          >
                            {paymentTypes.map(renderPaymentOptions)}
                          </Select>
                        )}
                      </InputGroup>
                    </Form.Item>

                    <Form.Item
                      {...formItemLayout}
                      label="Managers"
                      className="mb-3"
                    >
                      {getFieldDecorator("managers")(
                        <Select
                          showSearch
                          mode="multiple"
                          placeholder="Managers"
                          showArrow={false}
                          filterOption={false}
                          onSearch={e => this.searchManagers(e)}
                        >
                          {this.state.managers.map(manager => (
                            <Option key={manager.id} value={manager.id}>
                              {manager.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                    <Form.Item {...noLabelLayout} className="mb-3">
                      {getFieldDecorator("details", {
                        rules: options.rules.add
                      })(<TextArea rows="3" placeholder="Bank details" />)}
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn-primary add-client__btn"
                      >
                        {!isNumeric(actionOrId) ? "Save" : "Update"}
                      </Button>
                    </Form.Item>
                  </Form>
                </CardBody>
              </Card>
            </Spin>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Form.create({
  name: "client-form",
  mapPropsToFields: props => {
    const { currentClient, isFetching, formErrors } = props;
    const fieldNames = [
      "name",
      "attention",
      "address",
      "city",
      "state",
      "zip",
      "country",
      "tax_id",
      "currency_id",
      "payment_type_id",
      "details",
      "managers"
    ];
    if (!currentClient || isFetching) return {};
    const Errors = formErrors || {};

    const createFormFields = names => {
      const formFields = {};
      names.map(el => {
        return (formFields[el] = Form.createFormField({
          ...Errors[el],
          value: props.currentClient[el]
        }));
      });
      return formFields;
    };

    return createFormFields(fieldNames);
  },
  onValuesChange: (props, changed) => {
    props.setCurrentClient({ ...props.currentClient, ...changed });
  },
  onFieldsChange: (props, changed) => {
    const formErrors = { ...props.formErrors };

    props.setClientFormErrors({ ...formErrors, ...changed });
  }
})(ClientForm);
