import { connect } from "react-redux";
import actions from "store/invoices/actions";
import { onClientChanged } from "store/clients/actions";
import InvoiceForm from "components/invoices/InvoiceForm";
import { copyInvoice } from "store/invoices/actions/form";

const {
  getInvoice,
  getFormData,
  setCurrentInvoice,
  createInvoice,
  updateInvoice
} = actions;

const mapStateToProps = ({ invoices, user }) => ({
  role: user.role,
  current: invoices.current,
  formData: invoices.formData,
  loading: invoices.loading
});

const mapDispatchToProps = {
  getInvoice,
  getFormData,
  setCurrentInvoice,
  createInvoice,
  updateInvoice,
  onClientChanged,
  copyInvoice
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceForm);
