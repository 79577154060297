import { MAX_FILE_SIZE } from "../store/constants";

export const isNumeric = n => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};
export const getPageNumber = location => {
  if (!location || !location.search) {
    return 1;
  }

  const { search } = location;

  const regex = /[?|&]page=(\d+)/i;
  const match = search.match(regex);
  const page = match ? match[1] : 1;
  return page;
};

export const filterObjectToQueryString = (params = {}) => {
  return Object.keys(params)
    .map(key => (params[key] ? key + "=" + params[key] : ""))
    .filter(Boolean)
    .join("&");
};

export const extractUrlParams = history => {
  if (!history) {
    return;
  }
  let fitlers = {};
  let params = new URLSearchParams(history.location.search);
  if (!params.toString().length) return { page: 1 };
  for (let p of params) {
    fitlers[`${p[0]}`] = p[1];
  }
  return fitlers;
};

export const setPageNumber = (history, page = 1) => {
  if (!history) {
    return;
  }

  let params = new URLSearchParams(history.location.search);
  params.delete("page");
  const {
    location: { pathname }
  } = history;

  if (page === 1) params.delete("page");
  history.push({
    pathname,
    search:
      page !== 1
        ? `?page=${page}&${params.toString()}`
        : "?" + params.toString()
  });
};

export function isEquals(a, b) {
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);

  if (aProps.length !== bProps.length) {
    return false;
  }

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];

    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  return true;
}

export const validateFile = file => {
  const validExt = [
    "application/pdf",
    "application/jpg",
    "text/plain",
    "image/jpeg",
    "application/docx",
    "application/doc"
  ];
  if (file.size >= MAX_FILE_SIZE) return false;
  if (!validExt.includes(file.type)) return false;
  return true;
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
};
