import { ClientTable } from "components/clients/ClientTable";
import { connect } from "react-redux";
import { deleteClient, fetchClientPage } from "store/clients/actions";
import { setFilters } from "store/clients/actions";

const mapStateToProps = ({ clients }) => ({
  filters: clients.filters,
  values: clients.values,
  loading: clients.isFetching,
  pagination: clients.pagination,
  shortValues: clients.shortValues
});

const mapDispatchToProps = {
  setFilters: setFilters,
  fetchClients: fetchClientPage,
  remove: deleteClient
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientTable);
