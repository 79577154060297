import { connect } from 'react-redux';
import StaffForm from 'components/staff/StaffForm';
import { updateStaff, fetchStaffInfo, setCurrent } from 'store/staff/actions';

const mapStateToProps = ({ staff }) => ({
  current: staff.current,
  formState: staff.formState,
  loading: staff.isFetching
});

const mapDispatchToProps = {
  fetchStaffInfo: fetchStaffInfo,
  update: updateStaff,
  setCurrent: setCurrent
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StaffForm);
