import React from 'react';
import { Row, Col, Select, Form, Button, Spin } from 'antd';
import { Card, CardBody, Container } from 'shards-react';
import PageTitle from 'components/common/PageTitle';
import { history } from 'helpers/history';
import { staffFormItemLayout, staffBtnLayout } from 'helpers/formLayout';
import './StaffForm.css';
const { Option } = Select;
class StaffForm extends React.Component {
  componentDidMount() {
    const { fetchStaffInfo, match } = this.props;
    fetchStaffInfo(+match.params.id);
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading, formState, current, update } = this.props;
    const Current = current || {};
    const FormState = formState || { roles: [] };

    return (
      <Container fluid className="main-content-container">
        <Row type="flex" justify="center">
          <Col span={24} sm={20} md={16} lg={12} xl={10}>
            <Row type="flex" justify="space-between" className="page-header">
              <PageTitle title="Staff info" className="mr-sm-auto" />
              <Button
                type="secondary"
                name="goback"
                onClick={() => {
                  history.push('/staff');
                }}>
                Go Back
              </Button>
            </Row>
            <Spin spinning={!formState || !current || loading}>
              <Card small className="mb-4">
                <CardBody className="p-3">
                  <Form
                    className="staff-form"
                    layout="horizontal"
                    onSubmit={async event => {
                      event.preventDefault();
                      await update({ ...current });
                    }}>
                    <Form.Item label="Manager:" {...staffFormItemLayout}>
                      <span className="staff-item">{Current.name}</span>
                    </Form.Item>

                    <Form.Item label="E-mail:" {...staffFormItemLayout}>
                      <span className="staff-item">{Current.email}</span>
                    </Form.Item>

                    <Form.Item label="Role:" {...staffFormItemLayout}>
                      {getFieldDecorator('role', { initialValue: Current.role || 'manager' })(
                        <Select placeholder="Choose a role">
                          {FormState.roles.map(el => (
                            <Option key={el.name} value={el.id}>
                              {el.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>

                    <Form.Item {...staffBtnLayout} className="invite-form__btn">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn-primary add-client__btn">
                        Update
                      </Button>
                    </Form.Item>
                  </Form>
                </CardBody>
              </Card>
            </Spin>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Form.create({
  name: 'staff-form',
  onValuesChange: (props, changed) => {
    const { setCurrent, current } = props;
    setCurrent({ ...current, ...changed });
  }
})(StaffForm);
