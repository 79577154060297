import axiosInstance from "helpers/AxiosInstance";
import modals from "helpers/modals";
import {
  STAFF_FETCH_SUCCESS,
  FETCHING_STATE_OFF,
  FETCHING_STATE_ON,
  STAFF_SINGLE_FETCH_SUCCESS,
  SET_CURRENT_STAFF
} from "./actionTypes";
import { history } from "helpers/history";

export const setStaffData = data => ({
  type: STAFF_FETCH_SUCCESS,
  payload: data
});
export const setCurrent = data => ({
  type: SET_CURRENT_STAFF,
  payload: data
});
export const setCurrentStaff = data => ({
  type: STAFF_SINGLE_FETCH_SUCCESS,
  payload: data
});
export const setFetchingState = state => ({
  type: state ? FETCHING_STATE_ON : FETCHING_STATE_OFF
});
export const removeStaff = id => async dispatch => {
  await axiosInstance.delete(`/staff/${id}`);
};
export const createStaff = email => async dispatch => {
  try {
    dispatch(setFetchingState(true));
    const response = await axiosInstance.post(`/staff/`, { email: email });
    dispatch(setFetchingState(false));
    modals.success(response.data.message);
    history.push("/staff");
  } catch (error) {
    try {
      modals.error(error.response.data.errors.email);
    } catch {
      modals.error("Unable to log in!", "Please, use A2Design account.");
    }
  }
};

export const updateStaff = staffData => async dispatch => {
  try {
    dispatch(setFetchingState(true));
    const response = await axiosInstance.put(
      `/staff/${staffData.id}`,
      staffData
    );
    dispatch(setFetchingState(false));
    modals.success(response.data.message);
    history.push("/staff");
  } catch (error) {
    try {
      modals.error(error.response.data.error);
    } catch {
      modals.error("Unable to log in!", "Please, use A2Design account.");
    }
  }
};

export const fetchStaffInfo = id => async dispatch => {
  try {
    dispatch(setFetchingState(true));
    const response = await axiosInstance.get(`/staff/${id}/edit`);
    dispatch(setCurrentStaff(response.data));
    dispatch(setFetchingState(false));
  } catch (error) {
    try {
      modals.error(error.response.data.error);
    } catch {
      modals.error("Unable to log in!", "Please, use A2Design account.");
    }
  }
};

export const getStaff = page => async dispatch => {
  try {
    dispatch(setFetchingState(true));
    const response = await axiosInstance.get(`/staff?page=${page}`);
    dispatch(setStaffData(response.data));
  } catch (error) {
    try {
      modals.error(error.response.data.error);
    } catch {
      modals.error("Unable to log in!", "Please, use A2Design account.");
    }
  } finally {
    dispatch(setFetchingState(false));
  }
};
