export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    md: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 16 }
  }
};
export const staffFormItemLayout = {
  labelCol: {
    span: 5
  },
  wrapperCol: {
    span: 18
  }
};
export const staffBtnLayout = {
  wrapperCol: {
    xs: { offset: 0 },
    sm: { offset: 5 }
  }
};
export const inputGroupFirst = {
  labelCol: {
    xs: { span: 24 },
    md: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 8 }
  }
};
export const inputGroupSecond = {
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 8, offset: 13 }
  }
};
export const noLabelLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    md: {
      span: 16,
      offset: 5
    }
  }
};
export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    md: {
      span: 16,
      offset: 5
    }
  }
};
