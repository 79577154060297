import { message } from "antd";

message.config({
  top: 50,
  duration: 2,
  maxCount: 3
});
export default {
  success(title) {
    message.success(title);
  },
  error(title) {
    message.error(title);
  }
};
