import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Row, Button, Spin, Popover, Icon } from "antd";
import PageTitle from "components/common/PageTitle";
import { Container, Card } from "shards-react";
import {
  getPageNumber,
  setPageNumber,
  scrollToTop,
  extractUrlParams
} from "helpers/functions";
import InvoiceFilter from "../InvoiceFilters/index";
import TableWrapper from "./TableWrapper";

export default class InvoiceTable extends PureComponent {
  componentDidMount = () => {
    const { getInvoices, location } = this.props;
    const number = getPageNumber(location);

    getInvoices(number || 0);
  };
  async componentDidUpdate(prevProps) {
    const { pagination: prevPagination } = prevProps;
    const {
      pagination,
      location,
      history,
      setFilters,
      getInvoices,
      filters,
      formData,
      getFormData,
      loading
    } = this.props;
    !formData.status && !loading && (await getFormData());
    if (!pagination) {
      return;
    }

    const { current_page: current } = pagination;
    if (
      !prevPagination ||
      (!loading && parseInt(current) !== parseInt(getPageNumber(location)))
    ) {
      setFilters({ ...filters, ...extractUrlParams(history) });

      getInvoices();
    }
  }

  onPaginationChanged = page => {
    const { history } = this.props;
    setPageNumber(history, page);
    scrollToTop();
  };

  getPaginationData = () => {
    const { pagination } = this.props;

    if (pagination) {
      const { per_page: pageSize, current_page: current, total } = pagination;
      return {
        current,
        total,
        pageSize,
        onChange: this.onPaginationChanged
      };
    }
    if (pagination === false) return false;
    return {
      current: 1,
      total: 1,
      pageSize: 25
    };
  };

  handleTableChange = (pagination, _filters, sorter) => {
    const { setFilters, filters, getInvoices } = this.props;
    setFilters({
      ...filters,
      order: sorter.order === "descend" ? "desc" : "asc",
      page: pagination.current
    });
    getInvoices();
  };

  render() {
    const { props } = this;
    const {
      data,
      removeInvoice,
      setFilters,
      formData,
      getFormData,
      setStatus,
      getInvoices,
      filters,
      loading,
      isConverted,
      totalConverted,
      rates
    } = props;

    let pagination = this.getPaginationData();

    if (!pagination) {
      data.push({
        _id: -1,
        status: "Total:",
        total: totalConverted,
        currency: isConverted ? (
          <Popover content={"Currency convertion to USD applied."}>
            <Icon type="exclamation-circle" />
          </Popover>
        ) : (
          "USD"
        )
      });
    }

    return (
      <React.Fragment>
        <Container fluid className="main-content-container">
          <Row type="flex" justify="space-between" className="page-header">
            <PageTitle title="Invoices" className="mr-sm-auto" />
            <Link to="/invoices/new">
              <Button type="success" name="add">
                + Add new invoice
              </Button>
            </Link>
          </Row>
          <Spin spinning={loading}>
            <Card small className="mb-4">
              <InvoiceFilter
                filters={filters}
                setFilters={setFilters}
                formData={formData}
                getFormData={getFormData}
                getInvoices={getInvoices}
              />

              <TableWrapper
                handleTableChange={this.handleTableChange}
                data={data}
                setStatus={setStatus}
                pagination={pagination}
                rates={rates}
                removeInvoice={removeInvoice}
              />
            </Card>
          </Spin>
        </Container>
      </React.Fragment>
    );
  }
}
