import { connect } from "react-redux";
import LoginOverview from "components/login/LoginOverview";
import { login } from "store/user/actions";

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = {
  performLogin: login
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginOverview);
