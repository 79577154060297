import React from "react";
import { Select } from "antd";
const Option = Select.Option;

export const renderCurrencyOptions = (el, index) => {
  return (
    <Option key={"curr" + index} value={el.id}>
      {el.short_name}
    </Option>
  );
};

export const renderTaxOptions = (el, index) => {
  return (
    <Option key={"taxes" + index} value={el.id}>
      {el.name} {el.percent}%
    </Option>
  );
};

export const renderPaymentOptions = (el, index) => (
  <Option key={"payment" + index} value={el.id}>
    {el.name}
  </Option>
);
