import { LOGIN, LOGOUT, SET_ROLE } from './actionTypes';

const initialState = {
  token: null,
  user: null,
  role: 'manager'
};

const UserHandler = {
  [LOGIN](state, { payload }) {
    return {
      ...state,
      token: payload.access_token,
      user: payload.user,
      role: payload.role
    };
  },
  [SET_ROLE](state, { payload }) {
    return { ...state, role: payload };
  },
  [LOGOUT](state, action) {
    return { ...initialState };
  }
};

export default (state = initialState, action) =>
  UserHandler[action.type] ? UserHandler[action.type](state, action) : state;
