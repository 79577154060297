import React from "react";
import { DatePicker, Select, Row, Col, Form, Button } from "antd";
import "antd/lib/date-picker/style/css";
import { DATE_FORMAT } from "store/constants";
import "./InvoiceFilter.css";
import moment from "moment";
import { history } from "helpers/history";
import { filterObjectToQueryString } from "helpers/functions";
const Option = Select.Option;

export default class InvoiceFilter extends React.Component {
  selectSearch = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  handleStateChange(key, val) {
    const { filters, setFilters } = this.props;
    const copy = { ...filters };
    if (!val) {
      delete copy[key];
    } else if (["from", "to"].includes(key)) {
      if (val) copy[key] = val.format(DATE_FORMAT);
      else {
        delete copy[key];
        setFilters(copy);
        return;
      }
    } else copy[key] = val;
    setFilters(copy);
  }

  render() {
    const { formData, getInvoices, setFilters, filters } = this.props;

    const ManagersList = formData.managers_list || [];
    const Statuses = formData.status || [];
    const Clients = formData.clients || [];
    return (
      <Form>
        <Row type="flex" align="bottom" className="invoice-filter" gutter={10}>
          <Col span={12} sm={8} md={5} xxl={3} className="form-group mb-0">
            <label className="text-muted d-block mb-2">Client:</label>
            <Select
              id="recipient"
              showSearch
              placeholder="Select client"
              filterOption={this.selectSearch}
              onChange={id => this.handleStateChange("client_id", id)}
              value={filters.client_id}
            >
              <Option key={-1} value={null}>
                -
              </Option>
              {Clients.map(el => (
                <Option key={el.id}>{el.name}</Option>
              ))}
            </Select>
          </Col>

          {localStorage.getItem("role") === "admin" && (
            <Col span={12} sm={8} md={5} xxl={3} className="form-group mb-0">
              <label className="text-muted d-block mb-2">Manager:</label>
              <Select
                value={filters.manager_id}
                placeholder="Select manager"
                onChange={value => this.handleStateChange("manager_id", value)}
              >
                <Option key={-1} value={null}>
                  -
                </Option>
                {ManagersList.map(el => (
                  <Option key={el.id}>{el.name}</Option>
                ))}
              </Select>
            </Col>
          )}

          <Col span={12} sm={8} md={3} xxl={3} className="form-group mb-0">
            <label className="text-muted d-block mb-2">Status:</label>
            <Select
              value={filters.status_id}
              onChange={value => this.handleStateChange("status_id", value)}
              placeholder="Select status"
            >
              <Option key={-1} value={null}>
                -
              </Option>
              {Statuses.map(el => (
                <Option key={el.id}>{el.name}</Option>
              ))}
            </Select>
          </Col>

          <Col span={12} sm={8} md={4} xxl={3} className="form-group mb-0">
            <label htmlFor="chooseDate" className="text-muted d-block mb-2">
              From:
            </label>
            <DatePicker
              readonly
              format={DATE_FORMAT}
              value={filters.from ? moment(filters.from) : null}
              onChange={value => this.handleStateChange("from", value)}
            />
          </Col>

          <Col span={12} sm={8} md={4} xxl={3} className="form-group mb-0">
            <label htmlFor="chooseDueDate" className="text-muted d-block mb-2">
              To:
            </label>
            <DatePicker
              readonly
              format={DATE_FORMAT}
              value={filters.to ? moment(filters.to) : null}
              onChange={value => this.handleStateChange("to", value)}
            />
          </Col>

          <Col className="form-group mb-0">
            <Button
              type="primary"
              ghost
              className="btn_filter"
              onClick={() => {
                const { filters: params } = this.props;

                history.replace({
                  pathname: history.location.pathname,
                  search: filterObjectToQueryString(params)
                });
                getInvoices(0);
              }}
            >
              Filter
            </Button>
          </Col>
          <Col className="form-group mb-0">
            <span
              className="btn_clean"
              onClick={() => {
                setFilters({});
                history.replace({
                  pathname: history.location.pathname,
                  search: filterObjectToQueryString()
                });

                getInvoices(0);
              }}
            >
              Clear
            </span>
          </Col>
        </Row>
      </Form>
    );
  }
}
