import React from "react";
import { Nav } from "shards-react";
import { Button, Icon } from "antd";

import SidebarNavItem from "./SidebarNavItem";
import localStorageHelper from "helpers/localStorageHelper";
import { history } from "helpers/history";
import { getNavBarItems } from "store/constants";

class SidebarNavItems extends React.Component {
  onLogout = async () => {
    const { logout } = this.props;
    await logout();
    localStorageHelper.clearAuthData();
    history.push("/login");
  };

  render() {
    const { role } = this.props;
    const items = getNavBarItems(role);

    return (
      <div className="nav-wrapper nav-wrapper-flex main-navbar__wrapper">
        <Nav className="nav--no-borders flex-column main-navbar__list">
          {items.map((item, idx) => (
            <SidebarNavItem key={idx} item={item} />
          ))}
        </Nav>
        <Button
          onClick={this.onLogout}
          type="danger"
          ghost
          size="small"
          className="logout-btn"
        >
          <Icon type="logout" />
          <span className="logout-btn__text">Log out</span>
        </Button>
      </div>
    );
  }
}

export default SidebarNavItems;
