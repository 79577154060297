import {
  GET_FORM_DATA,
  GET_FORM_DATA_SUCCESS,
  GET_FORM_DATA_ERROR,
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_ERROR,
  SET_CURRENT_INVOICE,
  SET_SEARCH_STATE
} from "../actionTypes";
import axiosInstance from "helpers/AxiosInstance";
import modals from "helpers/modals";
import { history } from "helpers/history";
import moment from "moment";
import { DATE_FORMAT } from "store/constants";

const getFormDataAction = () => ({
  type: GET_FORM_DATA
});

export const getFormDataSuccess = payload => ({
  type: GET_FORM_DATA_SUCCESS,
  payload
});

const getFormDataError = payload => ({
  type: GET_FORM_DATA_ERROR,
  payload
});

export const getFormData = () => async dispatch => {
  try {
    dispatch(getFormDataAction());
    const response = await axiosInstance.get("/invoices/create");

    const { data } = response;
    if (!data) {
      throw new Error("There was a problem on our end. Please try again.");
    }

    dispatch(getFormDataSuccess(data));
  } catch (error) {
    dispatch(getFormDataError(error));
    try {
      modals.error(error.error);
    } catch {
      modals.error("There was a problem on our end. Please try again.");
    }
  }
};

const getInvoiceAction = () => ({
  type: GET_INVOICE
});

const getInvoiceSuccess = payload => ({
  type: GET_INVOICE_SUCCESS,
  payload
});

const getInvoiceError = payload => ({
  type: GET_INVOICE_ERROR,
  payload
});
export const setCurrentInvoice = payload => ({
  type: SET_CURRENT_INVOICE,
  payload
});

export const setSearchState = payload => ({
  type: SET_SEARCH_STATE,
  payload
});

export const fileDeleteRequest = async id => {
  try {
    const response = await axiosInstance.delete(`/files/${id}`);
    modals.success(response.data.message);
  } catch (error) {
    error.response && modals.error(error.response.data.message);
  }
};

export const createInvoice = invoiceData => async dispatch => {
  try {
    const invoice = { ...invoiceData };
    dispatch(getInvoiceAction());
    const { files } = invoice;

    files && (invoice.files = files.map(file => file.id));
    const response = await axiosInstance.post(`/invoices`, invoice);

    const { data } = response;
    if (!data) {
      throw new Error("There was a problem on our end. Please try again.");
    }

    modals.success("Invoice Created Successfully");
    history.push("/invoices");
  } catch (error) {
    dispatch(getInvoiceError(error));
    try {
      modals.error(error.error);
    } catch {
      modals.error("There was a problem on our end. Please try again.");
    }
  }
};
export const updateInvoice = invoiceData => async dispatch => {
  try {
    const invoice = { ...invoiceData };
    dispatch(getInvoiceAction());
    const { files } = invoice;

    files && (invoice.files = files.map(file => file.id));
    const response = await axiosInstance.put(
      `/invoices/${invoice.id}`,
      invoice
    );

    const { data } = response;
    if (!data) {
      throw new Error("There was a problem on our end. Please try again.");
    }

    modals.success("Invoice Updated Successfully");
    history.push("/invoices");
  } catch (error) {
    dispatch(getInvoiceError(error));
    try {
      modals.error(error.error);
    } catch {
      modals.error("There was a problem on our end. Please try again.");
    }
  }
};

export const copyInvoice = id => async dispatch => {
  try {
    dispatch(getInvoiceAction());
    const response = await axiosInstance.get(`/invoices/${id}/edit`);

    const { data } = response;
    const { payments, id: _id, ...invoice } = data;
    payments.forEach(p => delete p.id);
    if (!data) {
      throw new Error("There was a problem on our end. Please try again.");
    }
    invoice.issue_date = moment().format(DATE_FORMAT);
    invoice.due_date = moment().format(DATE_FORMAT);

    dispatch(
      getInvoiceSuccess({
        ...invoice,
        payments,
        files: []
      })
    );
  } catch (error) {
    dispatch(getInvoiceError(error));
    try {
      modals.error(error.message);
    } catch {
      modals.error("There was a problem on our end. Please try again.");
    }
  }
};

export const getInvoice = id => async dispatch => {
  try {
    dispatch(getInvoiceAction());
    const response = await axiosInstance.get(`/invoices/${id}/edit`);

    const { data } = response;
    if (!data) {
      throw new Error("There was a problem on our end. Please try again.");
    }

    dispatch(getInvoiceSuccess(data));
  } catch (error) {
    dispatch(getInvoiceError(error));
    try {
      modals.error(error.error);
    } catch {
      modals.error("There was a problem on our end. Please try again.");
    }
  }
};
