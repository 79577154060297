import React from "react";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { store } from "./store/index";
import routes from "./routes";

import { history } from "./helpers/history";
import { Result } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";

import "./assets/shards-dashboards.1.1.0.min.css";
import "./index.css";
import { loginFromLocalStorage } from "store/user/actions";

require("dotenv").config();

store.dispatch(loginFromLocalStorage());

function App() {
  return (
    <Router history={history}>
      <Provider store={store}>
        <Switch>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={props => {
                  return (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  );
                }}
              />
            );
          })}
          <Result error={404} title="The page is not found." />
        </Switch>
      </Provider>
    </Router>
  );
}

export default App;
