import { LOGIN, SET_ROLE } from "./actionTypes";
import axiosInstance from "helpers/AxiosInstance";
import localStorageHelper from "helpers/localStorageHelper";
import modals from "helpers/modals";

export const loginWith = data => ({
  type: LOGIN,
  payload: data
});
export const setRole = role => ({
  type: SET_ROLE,
  payload: role
});

export const loginFromLocalStorage = () => {
  const authData = localStorageHelper.getAuthData();
  return {
    type: LOGIN,
    payload: authData
  };
};

export const login = token => async dispatch => {
  try {
    const response = await axiosInstance.post("/auth/login/switchToken", {
      token: token.tokenObj.access_token
    });

    localStorageHelper.setAuthData(response.data);

    dispatch(loginWith(response.data));
  } catch (error) {
    console.error(error);
    try {
      modals.error(error.response.data.error);
    } catch {
      modals.error("Unable to log in!", "Please, use A2Design account.");
    }
  }
};
