import {
  GET_FORM_DATA,
  GET_FORM_DATA_SUCCESS,
  GET_FORM_DATA_ERROR,
  GET_LIST_DATA,
  GET_LIST_DATA_SUCCESS,
  GET_LIST_DATA_ERROR,
  GET_INVOICE,
  GET_INVOICE_ERROR,
  GET_INVOICE_SUCCESS,
  SET_CURRENT_INVOICE,
  SET_FILTERS,
  SET_SEARCH_STATE
} from "./actionTypes";

const initialState = {
  formData: {},
  invoices: [],
  filters: {
    client_id: undefined,
    manager_id: undefined,
    status_id: undefined,
    from: undefined,
    to: undefined,
    order: "desc",
    page: 0
  },
  totalConverted: undefined,
  isConverted: false,
  rates: [],
  search: "",
  current: {},
  error: false,
  loading: false,
  sending: false
};

const handler = {
  [GET_FORM_DATA]: (state, action) => {
    return { ...state, error: false, loading: true };
  },
  [GET_FORM_DATA_SUCCESS]: (state, action) => {
    return { ...state, formData: action.payload, loading: false };
  },
  [GET_FORM_DATA_ERROR]: (state, action) => {
    return { ...state, error: true, loading: false };
  },
  [GET_LIST_DATA]: (state, action) => {
    return { ...state, error: false, loading: true };
  },
  [GET_LIST_DATA_SUCCESS]: (state, action) => {
    const { data, ...rest } = action.payload;
    return {
      ...state,
      invoices: data,
      ...rest,
      loading: false
    };
  },
  [GET_LIST_DATA_ERROR]: state => {
    return { ...state, error: true, loading: false };
  },
  [GET_INVOICE]: state => {
    return { ...state, error: false, loading: true };
  },
  [GET_INVOICE_SUCCESS]: (state, action) => {
    return { ...state, current: action.payload, loading: false };
  },
  [GET_INVOICE_ERROR]: state => {
    return { ...state, error: true, loading: false };
  },
  [SET_CURRENT_INVOICE]: (state, action) => {
    return { ...state, current: action.payload };
  },
  [SET_SEARCH_STATE]: (state, action) => {
    return { ...state, search: action.payload };
  },
  [SET_FILTERS]: (state, action) => {
    return { ...state, filters: action.payload };
  }
};

export default (state = initialState, action) => (handler[action.type] ? handler[action.type](state, action) : state);
