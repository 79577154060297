import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Row, Button, Col, Menu, Popconfirm, Dropdown } from 'antd';
import { history } from 'helpers/history';
import PageTitle from 'components/common/PageTitle';
import { Container, Card, CardBody } from 'shards-react';
import { Table } from 'antd';

export default class StaffTable extends PureComponent {
  componentDidMount() {
    const { fetchStaff } = this.props;
    fetchStaff(0);
  }

  render() {
    const { props } = this;
    const { data, removeStaff, fetchStaff, loading } = props;
    const options = record => {
      return (
        <Menu>
          <Menu.Item key="1">
            <Link className="view-client-link" to={'/staff/' + record.id}>
              View manager
            </Link>
          </Menu.Item>
          <Menu.Item key="2" style={{ padding: '0' }}>
            <Popconfirm
              placement="bottomRight"
              title="Do you want to remove this manager?"
              onConfirm={async () => {
                await removeStaff(record.id);
                fetchStaff(0);
              }}
            >
              <span style={{ display: 'block', padding: '5px 12px' }}>Remove manager</span>
            </Popconfirm>
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <React.Fragment>
        <Container fluid className="main-content-container">
          <Row type="flex" justify="center">
            <Col span={24} md={16} lg={20} className="form-col">
              <Row type="flex" justify="space-between" className="page-header">
                <PageTitle title="Staff" className="mr-sm-auto" />
                <Button
                  type="success"
                  name="add"
                  onClick={() => {
                    history.push('/staff/invite');
                  }}
                >
                  + Invite a manager
                </Button>
              </Row>
              <Card small className="mb-4">
                <CardBody className="p-0">
                  <Table
                    dataSource={data}
                    loading={loading}
                    rowKey={record => record.id + 'rowCl'}
                    scroll={{ x: true }}
                    columns={[
                      {
                        title: 'ID',
                        dataIndex: 'id',
                        key: 'id',
                        width: '5%',
                        render: id => <Link to={`/staff/${id}`}>{id}</Link>
                      },
                      {
                        title: 'Name',
                        width: '25%',
                        render: (text, record) => (
                          <Link to={`/staff/${record.id}`}>
                            <span className="nowrap-text">{record.name}</span>
                          </Link>
                        )
                      },
                      {
                        title: 'E-mail',
                        dataIndex: 'email',
                        width: '25%',
                        render: text => <span className="nowrap-text">{text}</span>
                      },
                      {
                        title: 'Role',
                        dataIndex: 'role',
                        width: '15%',
                        render: text => <span className="nowrap-text">{text}</span>
                      },
                      {
                        title: 'Action',
                        key: 'action',
                        width: '4%',
                        render: (id, record) => (
                          <Dropdown
                            overlay={options(record)}
                            placement="bottomRight"
                            trigger={['click']}
                          >
                            <div className="option-btn" />
                          </Dropdown>
                        )
                      }
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
