import React from "react";
import { Row, Col } from "antd";
import { Container } from "shards-react";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";

const DefaultLayout = ({ children }) => (
  <Container fluid className="p-0" style={{ position: "relative", minHeight: "100vh" }}>
    <Row type="flex">
      <MainSidebar />
      <Col
        className="main-content"
        lg={{ span: 20, offset: 4 }}
        md={{ span: 24, offset: 0 }}
        xs={24}
        tag="main"
      >
        <MainNavbar />
        {children}
      </Col>
    </Row>
    <div className="version-span">
      <span>Version 1.6</span>
    </div>
  </Container>
);

export default DefaultLayout;
