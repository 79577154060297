import {
  FETCHING_STATE_OFF,
  FETCHING_STATE_ON,
  STAFF_FETCH_SUCCESS,
  STAFF_SINGLE_FETCH_SUCCESS,
  SET_CURRENT_STAFF
} from './actionTypes';

const initialState = {
  formState: null,
  current: null,
  data: [],
  pagination: null,
  isFetching: false
};

const StaffHandler = {
  [FETCHING_STATE_ON](state) {
    return { ...state, isFetching: true };
  },
  [FETCHING_STATE_OFF](state) {
    return { ...state, isFetching: false };
  },
  [STAFF_FETCH_SUCCESS](state, action) {
    return { ...state, data: action.payload.data, pagination: action.payload.pagination };
  },
  [STAFF_SINGLE_FETCH_SUCCESS](state, { payload }) {
    return { ...state, formState: { roles: payload.roles }, current: payload.data };
  },
  [SET_CURRENT_STAFF](state, action) {
    return { ...state, current: action.payload };
  }
};

export default (state = initialState, action) =>
  StaffHandler[action.type] ? StaffHandler[action.type](state, action) : state;
