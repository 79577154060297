import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Col } from "antd";
import { connect } from "react-redux";
import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarNavItems from "./SidebarNavItems";
import { logout } from "store/clients/actions";
class MainSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuVisible: false
    };
  }
  render() {
    const classes = classNames(
      "main-sidebar",
      "px-0",
      this.state.menuVisible && "open"
    );
    const { role, logout, hideLogoText } = this.props;
    return (
      <Col tag="aside" className={classes} lg={4}>
        <SidebarMainNavbar hideLogoText={hideLogoText} />
        <SidebarNavItems logout={logout} role={role} />
      </Col>
    );
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: false
};

export default connect(
  state => ({
    role: state.user.role
  }),
  {
    logout: logout
  }
)(MainSidebar);
