import Axios from "helpers/AxiosInstance";
import {
  CLIENT_FETCH_SUCCESS,
  FETCHING_STATE_ON,
  SET_AS_CURRENT_CLIENT,
  CLIENT_SINGLE_FETCH_SUCCESS,
  SET_FORM_ERRORS,
  FETCHING_STATE_OFF,
  SET_CLIENT_FORM_STATE,
  SET_PRIMARY_PERSONS_ERRORS
} from "./actionTypes";
import modals from "helpers/modals";
import { history } from "helpers/history";
import { store } from "store/index";
import { setCurrentInvoice } from "store/invoices/actions/form";
import { SET_FILTERS } from "store/clients/actionTypes";
const uuidv1 = require("uuid/v1");

export const setFetchingState = state => ({
  type: state ? FETCHING_STATE_ON : FETCHING_STATE_OFF
});

export const setClientFormState = payload => ({
  type: SET_CLIENT_FORM_STATE,
  payload
});
export const setPrimaryPersonsErrors = payload => ({
  type: SET_PRIMARY_PERSONS_ERRORS,
  payload
});
export const setClientTableValues = payload => ({
  type: CLIENT_FETCH_SUCCESS,
  payload
});

export const fetchClientFormState = () => async dispatch => {
  dispatch(setFetchingState(true));
  try {
    const response = await Axios.get("/clients/create");
    dispatch(setClientFormState(response.data));
  } catch (error) {
    dispatch(setFetchingState(false));
  }
};

export const fetchClientPage = (page = 1) => async dispatch => {
  dispatch(setFetchingState(true));
  const filter = store.getState().clients.filters;

  try {
    const response = await Axios.get(`/clients?page=${page}`, {
      params: {
        id: filter.id || undefined
      }
    });
    const { pagination } = response.data;
    if (pagination.current_page <= pagination.total_pages) {
      dispatch(setClientTableValues(response.data));
      return;
    }
    const res = await Axios.get(`/clients?page=${pagination.total_pages}`);
    dispatch(setClientTableValues(res.data));
  } catch (error) {
    dispatch(setFetchingState(false));
  }
};
export const setCurrentClient = currentClient => ({
  type: SET_AS_CURRENT_CLIENT,
  payload: currentClient
});

export const setClientFormErrors = errors => ({
  type: SET_FORM_ERRORS,
  payload: errors
});

export const setClient = client => ({
  type: CLIENT_SINGLE_FETCH_SUCCESS,
  payload: client
});

export const fetchClientInfo = id => async dispatch => {
  dispatch(setFetchingState(true));
  try {
    let client = await Axios.get(`/clients/${id}/edit`);
    dispatch(setClient(client.data));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(setFetchingState(false));
  }
};

export const createClient = clientData => async dispatch => {
  dispatch(setFetchingState(true));
  try {
    await Axios.post("/clients", clientData);
    modals.success("New client has been saved.");
    history.push("/clients");
  } catch (error) {
    modals.error("There was an error while creating a client.");
  } finally {
    dispatch(setFetchingState(false));
  }
};
export const deleteClient = id => async () => {
  await Axios.delete(`/clients/${id}`);
  modals.success("The client has been removed.");
};

export const updateClient = clientData => async dispatch => {
  dispatch(setFetchingState(true));
  try {
    await Axios.put(`/clients/${clientData.id}`, clientData);
    modals.success("The client has been updated.");
    history.push("/clients");
  } catch (error) {
    modals.error("There was an error while updating the client.");
  } finally {
    dispatch(setFetchingState(false));
  }
};
export const logout = () => async dispatch => {
  await Axios.post("/auth/logout");
};

export const fileUploadRequest = async options => {
  options.onProgress();
  const currentClient = { ...store.getState().clients.currentClient };
  const uid = uuidv1();
  !currentClient.files && (currentClient.files = []);
  currentClient.files.push({
    uid,
    name: options.file.name,
    status: "uploading"
  });
  store.dispatch({
    type: SET_AS_CURRENT_CLIENT,
    payload: currentClient
  });
  const data = new FormData();
  data.append("file", options.file);
  const config = {
    headers: {
      "content-type": "multipart/form-data"
    }
  };
  let result = 0;
  const filedef = [...currentClient.files].filter(el => el.uid !== uid);
  try {
    const response = await Axios.post(options.action, data, config);
    result = { ...response.data.data, uid };
    options.onSuccess(response.data.data, options.file);
  } catch (error) {
    options.onSuccess();
    result = {
      ...error.response.data,
      uid,
      name: options.file.name,
      status: "error",
      response: error.response.data.errors.file[0]
    };
  } finally {
    store.dispatch({
      type: SET_AS_CURRENT_CLIENT,
      payload: {
        ...currentClient,
        files: [...filedef, result]
      }
    });
  }
};

export const setFilters = filters => ({
  type: SET_FILTERS,
  payload: filters || {}
});

export const onClientChanged = client_id => async dispatch => {
  dispatch(setFetchingState(true));
  try {
    const response = await Axios.get(`/clients/${client_id}`);
    dispatch(
      setCurrentInvoice({
        ...store.getState().invoices.current,
        ...response.data,
        id: store.getState().invoices.current.id
      })
    );
  } catch (error) {
    modals.error("There was an error while fetching client's data.");
  } finally {
    dispatch(setFetchingState(false));
  }
};

export const fileDeleteRequest = async id => {
  try {
    let response = "";

    response = await Axios.delete(`/files/${id}`);
    modals.success(response.data.message);
  } catch (error) {
    error.response && modals.error(error.response.data.message);
  }
};
