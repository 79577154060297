import React from "react";
import { Form, Input } from "antd";
import "./InvoiceInfo.css";

class InvoiceInfo extends React.Component {
  render() {
    const { form, current } = this.props;

    const { getFieldDecorator } = form;

    return (
      <Form.Item
        label="Invoice Link:"
        className="invoice-info__item"
        style={{ width: "100%" }}
      >
        {getFieldDecorator("url", { initialValue: current.url })(<Input />)}
      </Form.Item>
    );
  }
}

export default Form.create({
  onValuesChange: (props, values) => {
    let { current, setCurrentInvoice } = props;
    setCurrentInvoice({ ...current, url: values.url });
  }
})(InvoiceInfo);
