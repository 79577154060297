import React from "react";
import numeral from "numeral";
import moment from "moment";
import { DATE_FORMAT } from "store/constants";
import { Link } from "react-router-dom";
import { Dropdown, Popover, Menu, Popconfirm } from "antd";
import { isNumeric } from "helpers/functions";
import { CurrencyPopup } from "./currencyPopup";
import StatusCell from "./StatusCell/index";
import { history } from "helpers/history";
const options = (record, removeInvoice) => {
  return (
    <Menu>
      <Menu.Item key="1">
        <Link className="view-client-link" to={`/invoices/${record.id}`}>
          View invoice
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <span
          className="view-client-link"
          onClick={() => {
            history.push({
              pathname: `/invoices/new?copy=${record.id}`,
              state: record.id
            });
          }}
        >
          Duplicate
        </span>
      </Menu.Item>
      <Menu.Item key="3" style={{ padding: "0" }}>
        <Popconfirm
          placement="bottomRight"
          title="Do you want to remove this invoice?"
          onConfirm={() => removeInvoice(record.id)}
        >
          <span style={{ display: "block", padding: "5px 12px" }}>
            Remove invoice
          </span>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );
};
export const invoiceTableColumns = (removeInvoice, rates, setStatus) => [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sorter: true,
    width: "5%",
    render: id =>
      isNumeric(id) ? <Link to={`/invoices/${id}`}>{id}</Link> : id
  },
  {
    title: "Client",
    dataIndex: "client_name",
    width: "20%",
    render: (text, { id }) => <Link to={`/invoices/${id}`}>{text}</Link>
  },
  {
    title: "Manager",
    dataIndex: "manager_name",
    width: "15%",
    render: text => <span className="nowrap-text">{text}</span>
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "10%",
    render: (text, record) => {
      const obj = {
        children: record.id ? (
          <StatusCell record={record} setStatus={setStatus} />
        ) : (
          <span className="nowrap-text total-text">{text}</span>
        ),
        props: {
          className: !record.id ? "total-align" : ""
        }
      };

      return obj;
    }
  },

  {
    title: "Total",
    dataIndex: "total",
    width: "13%",
    align: "right",
    render: (text, record) =>
      record.status !== "Total:" ? (
        <span>{numeral(text).format("0,0.00")}</span>
      ) : (
        <span className="total-text">{numeral(text).format("0,0.00")}</span>
      )
  },
  {
    title: "",
    dataIndex: "currency",
    key: "currency",
    width: "7%",
    className: "antd-table-col-currency",
    render: (value, row, index) => {
      const isTotal = row.status !== "Total:";
      const obj = {
        children: isTotal ? (
          <small>
            {value !== "USD" ? (
              <Popover
                content={
                  <CurrencyPopup
                    value={row.total}
                    currency={row.currency}
                    rates={rates}
                  ></CurrencyPopup>
                }
              >
                <span className="currency-td">{value}</span>
              </Popover>
            ) : (
              ""
            )}
          </small>
        ) : (
          value
        ),
        props: {}
      };

      return obj;
    }
  },
  {
    title: "Date",
    dataIndex: "date",
    sorter: true,
    width: "13%",
    defaultSortOrder: "descend",
    render: (date, record) => (
      <span className="nowrap-text">
        {record.date ? moment(record.date).format(DATE_FORMAT) : undefined}
      </span>
    )
  },
  {
    title: "Due Date",
    dataIndex: "due_date",
    sorter: true,
    width: "13%",
    render: (date, record) => {
      const dueDateStyle = record.is_expired
        ? "date-expired nowrap-text"
        : "nowrap-text";
      return (
        <span className={dueDateStyle}>
          {record.date
            ? moment(record.due_date).format(DATE_FORMAT)
            : undefined}
        </span>
      );
    }
  },
  {
    title: "Invoice ID",
    dataIndex: "custom_id",
    width: "15%",
    render: (text, record) => (
      <span className="nowrap-text">
        {!record.url || record.url === "" ? (
          text
        ) : (
          <a href={record.url}>{text}</a>
        )}
      </span>
    )
  },
  {
    title: "Action",
    key: "action",
    width: "4%",
    render: (id, record) =>
      record.status !== "Total:" ? (
        <Dropdown
          overlay={options(record, removeInvoice)}
          placement="bottomRight"
          trigger={["click"]}
        >
          <div className="option-btn" />
        </Dropdown>
      ) : (
        undefined
      )
  }
];
