import axios from "axios";
import moment from "moment";
import localStorageHelper from "./localStorageHelper";
import { history } from "helpers/history";
import { store } from "store/index";
import { loginFromLocalStorage } from "store/user/actions";

moment.locale("eu");

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["X-Cache-Control"] = "no-cache";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

axiosInstance.interceptors.request.use(
  config => {
    config.headers.Authorization = "";

    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = token;
    }

    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    let data = error.response;
    let dataStatus = "";
    let errorMessage = "";

    if (data) {
      dataStatus = data.status;
      errorMessage = data.data ? data.data.error : "";
    }

    if (dataStatus === 404) return history.push("/");
    if (dataStatus !== 401) return Promise.reject(error);
    if (errorMessage !== "Token is Expired") {
      localStorageHelper.clearAuthData();

      if (window.location.pathname === "/login") {
        return Promise.reject(error);
      }

      history.push("/");

      return Promise.reject(error);
    }

    return refreshToken()
      .then(response => {
        localStorageHelper.setAuthData(response.data);
        store.dispatch(loginFromLocalStorage());

        return axiosInstance(error.config);
      })
      .catch(errorResponse => {
        localStorageHelper.clearAuthData();
        history.push("/");

        return Promise.reject(errorResponse);
      });
  }
);

function refreshToken() {
  return axiosInstance.post("/auth/refresh");
}

export default axiosInstance;
