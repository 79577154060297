import React from "react";
import numeral from "numeral";
export const CurrencyPopup = ({ value, currency, rates }) => {
  return (
    <div className="currency-popup">
      <h6>
        <b>{numeral((value / rates[currency]).toFixed(2)).format("0,0.00")}</b>
        {`  USD`}
      </h6>
      <ul>
        <li>{`1 USD = ${rates[currency]} ${currency}`}</li>
        <li>{`1 ${currency} = ${(1 / rates[currency]).toFixed(6)} USD`}</li>
      </ul>
    </div>
  );
};
