import React from "react";
import Axios from "helpers/AxiosInstance";
import { validateFile } from "store/constants";
import { Col, Button, Upload, Icon } from "antd";
import "./Upload.css";
import { fileDeleteRequest } from "store/clients/actions";

var uuidv1 = require("uuid/v1");
const FileUpload = ({ current, setCurrentInvoice, loading }) => {
  const fileUploadRequest = async options => {
    const currentInvoice = { ...current };
    const uid = uuidv1();
    !currentInvoice.files && (currentInvoice.files = []);
    currentInvoice.files.push({
      uid,
      name: options.file.name,
      status: "uploading"
    });
    setCurrentInvoice(currentInvoice);
    const data = new FormData();
    data.append("file", options.file);
    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };
    let result = 0;
    const filedef = [...currentInvoice.files].filter(el => el.uid !== uid);
    try {
      const response = await Axios.post(options.action, data, config);

      result = { ...response.data.data };
      options.onSuccess(response.data.data, options.file);
    } catch (error) {
      options.onSuccess();
      result = {
        ...error.response.data,
        uid,
        name: options.file.name,
        status: "error",
        response: error.response.data.errors.file[0]
      };
    } finally {
      setCurrentInvoice({
        ...currentInvoice,
        files: [...filedef, result]
      });
    }
  };
  return (
    <Col span={24} xs={16} md={8}>
      <div className="upload-btn-wrap">
        <Upload
          className="upload-btn"
          name="fileUpload"
          fileList={current.files}
          action={`${process.env.REACT_APP_BACKEND_URL}files/upload`}
          accept=".jpeg,.jpg,.pdf,.txt,.doc,.docx"
          customRequest={fileUploadRequest}
          beforeUpload={file => {
            const valid = validateFile(file);
            if (!valid) {
              setCurrentInvoice({
                ...current,
                files: [
                  ...current.files,
                  {
                    uid: uuidv1(),
                    name: file.name,
                    status: "error",
                    response: "Invalid file ext or size"
                  }
                ]
              });
              return false;
            }
            return true;
          }}
          onRemove={async file => {
            const filedef = current.files || [];
            file.id && (await fileDeleteRequest(file.id));
            setCurrentInvoice({
              ...current,
              files: filedef.filter(el => el.uid !== file.uid)
            });
          }}
        >
          <Button>
            <Icon type="upload" /> Upload
          </Button>
        </Upload>
      </div>
    </Col>
  );
};
export default FileUpload;
