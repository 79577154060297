import React from "react";
import { Row, Col, Button, Form, Spin } from "antd";
import { history } from "helpers/history";
import { isNumeric } from "helpers/functions";
import PageTitle from "components/common/PageTitle";
import { Container, Card, CardFooter, CardBody } from "shards-react";
import PaymentRowContainer from "./PaymentRowContainer";
import UpperRow from "./UpperRow";
import FileUpload from "./FileUpload";
import Total from "./Total";
import InvoiceInfo from "./InvoiceInfo";

import "./InvoiceForm.css";
import { DATE_FORMAT } from "store/constants";
import { Link } from "react-router-dom";

export default class InvoiceForm extends React.Component {
  state = {
    tax: 0,
    subtotal: 0
  };
  componentDidUpdate = prevProps => {
    const prev = prevProps.current.tax_id;
    const curr = this.props.current.tax_id;
    if (prev !== curr) this.countTotal();
  };

  componentDidMount = async () => {
    const {
      match: {
        params: { actionOrId }
      },
      getInvoice,
      copyInvoice,
      getFormData,
      setCurrentInvoice
    } = this.props;

    await getFormData();
    let params = new URLSearchParams(history.location.search);
    if (history.location.state || params.get("copy")) {
      copyInvoice(history.location.state || params.get("copy"));
      return;
    }
    if (isNumeric(actionOrId)) {
      getInvoice(+actionOrId);
      return;
    }

    setCurrentInvoice({ payments: [] });
  };

  parsePaymentRows = () => {
    const { current } = this.props;
    if (!current || !current.payments) return [];
    const dataObject = this.PaymentRows.props.form.getFieldsValue();
    const payments = [];
    for (let i = 0; i < current.payments.length; i++) {
      let obj = {};
      obj.description = dataObject[`description-${i}`];
      obj.qty = dataObject[`qty-${i}`];
      obj.unit_price = dataObject[`unit_price-${i}`];
      payments.push(obj);
    }
    return payments;
  };

  countTotal = taxValue => {
    this.setState({
      tax: taxValue || this.parseUpperRow().tax_id,
      subtotal: this.parsePaymentRows().reduce((sum, current) => {
        const { qty, unit_price } = current;
        return sum + Math.round(qty * +unit_price * 10) / 10;
      }, 0)
    });
  };

  parseUpperRow = () => {
    const data = this.upperRow.props.form.getFieldsValue();
    data.issue_date = data.issue_date.format(DATE_FORMAT);
    data.due_date = data.due_date.format(DATE_FORMAT);
    return data;
  };

  render() {
    const {
      current,
      match: {
        params: { actionOrId }
      },
      formData,
      setCurrentInvoice,
      createInvoice,
      updateInvoice,
      onClientChanged,
      role,
      loading
    } = this.props;
    return (
      <React.Fragment>
        <Container fluid className="main-content-container">
          <Row type="flex" justify="center">
            <Col lg={24} xl={20} className="form-col invoice-form-col">
              <Row type="flex" justify="space-between" className="page-header">
                <PageTitle
                  title={
                    isNumeric(actionOrId) ? "Invoice info" : "Add new invoice"
                  }
                  className="mr-sm-auto"
                />
                <Link to="/invoices">
                  <Button type="secondary" name="goback">
                    Go Back
                  </Button>
                </Link>
              </Row>
              <Spin spinning={loading}>
                <Card small className="mb-4">
                  <Form
                    className="invoice-form"
                    onSubmit={event => {
                      event.preventDefault();
                      let valid = true;
                      this.PaymentRows.props.form.validateFields(error => {
                        if (error) valid = false;
                      });
                      this.upperRow.props.form.validateFields(error => {
                        if (error) valid = false;
                      });
                      if (!valid) return;
                      let invoice = {
                        ...current,
                        payments: this.parsePaymentRows(),
                        ...this.parseUpperRow()
                      };

                      if (isNumeric(actionOrId)) {
                        updateInvoice(invoice);
                        return;
                      }
                      createInvoice(invoice);
                    }}
                  >
                    <CardBody className="p-0">
                      <UpperRow
                        wrappedComponentRef={ref => (this.upperRow = ref)}
                        onClientChanged={onClientChanged}
                        countTotal={this.countTotal}
                        managers={formData.managers_list}
                        clients={formData.clients || []}
                        currencies={formData.currency || []}
                        statuses={formData.status || []}
                        taxes={formData.tax || []}
                        current={current}
                        role={role}
                        setCurrentInvoice={setCurrentInvoice}
                      />
                      <PaymentRowContainer
                        countTotal={this.countTotal}
                        wrappedComponentRef={ref => (this.PaymentRows = ref)}
                        current={current}
                        setCurrentInvoice={setCurrentInvoice}
                      />
                      <CardFooter
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%"
                        }}
                        className="card-extra"
                      >
                        <Row
                          type="flex"
                          justify="space-between"
                          style={{ alignSelf: "flex-end", width: "40%" }}
                          align="top"
                          className="m-0 card-extra-row"
                        >
                          <Total
                            countTotal={this.countTotal}
                            subtotal={this.state.subtotal}
                            current={current}
                            tax={this.state.tax}
                            taxes={formData.tax || []}
                            actionOrId={actionOrId}
                          />
                        </Row>
                        <Row
                          type="flex"
                          align="top"
                          className="m-0 card-info-row"
                        >
                          <InvoiceInfo
                            setCurrentInvoice={setCurrentInvoice}
                            current={current}
                          />
                        </Row>
                        <Row
                          type="flex"
                          align="top"
                          style={{ display: "block" }}
                        >
                          <FileUpload
                            current={current}
                            setCurrentInvoice={setCurrentInvoice}
                          />
                          <div className="total-btn-wrap"></div>
                        </Row>
                        <Row>
                          <Button
                            type="primary"
                            style={{
                              float: "right"
                            }}
                            htmlType="submit"
                            disabled={
                              !current.payments || !current.payments.length
                            }
                          >
                            {!isNumeric(actionOrId) ? "Save" : "Update"}
                          </Button>
                        </Row>
                      </CardFooter>
                    </CardBody>
                  </Form>
                </Card>
              </Spin>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
