export const FETCHING_STATE_ON = "FETCHING_STATE_ON";
export const FETCHING_STATE_OFF = "FETCHING_STATE_OFF";
export const CLIENT_FETCH_SUCCESS = "CLIENT_FETCH_SUCCESS";
export const CLIENT_FETCH_FAILURE = "CLIENT_FETCH_FAILURE";
export const SET_PRIMARY_PERSONS_ERRORS = "SET_PRIMARY_PERSONS_ERRORS";

export const SET_AS_CURRENT_CLIENT = "SET_AS_CURRENT_CLIENT";
export const CLIENT_SINGLE_FETCH_SUCCESS = "CLIENT_SINGLE_FETCH_SUCCESS";

export const SET_PAGINATION_STATE = "SET_PAGINATION_STATE";
export const SET_CLIENT_FORM_STATE = "SET_CLIENT_FORM_STATE";

export const SENDING_STATE_ON = "SENDING_STATE_ON";
export const SENDING_STATE_OFF = "SENDING_STATE_OFF";

export const SET_FORM_ERRORS = "SET_FORM_ERRORS";
export const SET_FILTERS = "SET_FILTERS";
