import React from "react";
import { Link } from "react-router-dom";

import PageTitle from "components/common/PageTitle";
import { Container, Card, CardBody } from "shards-react";
import { history } from "helpers/history";
import {
  getPageNumber,
  setPageNumber,
  scrollToTop,
  extractUrlParams
} from "helpers/functions";
import ClientFilter from "components/clients/ClientFilter";
import {
  Table,
  Dropdown,
  Menu,
  Popconfirm,
  Button,
  Row,
  Spin,
  Popover
} from "antd";

export class ClientTable extends React.Component {
  componentDidMount() {
    const { fetchClients, location } = this.props;
    const number = getPageNumber(location);
    fetchClients(number || 0);
  }
  componentDidUpdate(prevProps) {
    const {
      pagination: { total: prevTotal }
    } = prevProps;
    const {
      pagination: { total, current_page: current },
      location,
      filters,
      setFilters,
      fetchClients,
      history
    } = this.props;
    if (total !== prevTotal && current !== getPageNumber(location)) {
      setPageNumber(history, current);
      setFilters({ ...filters, ...extractUrlParams(history) });
      fetchClients(0);
    }
  }
  onPaginationStateChanged = (current_page, per_page) => {
    const { fetchClients } = this.props;
    fetchClients(current_page, per_page);
    setPageNumber(history, current_page);
    scrollToTop();
  };
  removeClient = async id => {
    const { pagination, remove } = this.props;
    await remove(id);
    this.onPaginationStateChanged(pagination.current_page, pagination.per_page);
  };

  render() {
    const {
      pagination,
      loading,
      values,
      shortValues,
      fetchClients,
      filters,
      setFilters
    } = this.props;
    const options = record => {
      return (
        <Menu>
          <Menu.Item key="1">
            <Link className="view-client-link" to={"/clients/" + record.id}>
              View client
            </Link>
          </Menu.Item>
          <Menu.Item key="2" style={{ padding: "0" }}>
            <Popconfirm
              placement="bottomRight"
              title="Do you want to remove this client?"
              onConfirm={() => this.removeClient(record.id)}
            >
              <span style={{ display: "block", padding: "5px 12px" }}>
                Remove client
              </span>
            </Popconfirm>
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <Container fluid className="main-content-container">
        <Row type="flex" justify="space-between" className="page-header">
          <PageTitle title="Clients" className="mr-sm-auto" />
          <Button
            type="success"
            name="add"
            onClick={() => {
              history.push("/clients/new");
            }}
          >
            + Add new client
          </Button>
        </Row>
        <Spin spinning={loading}>
          <Card small className="mb-4">
            <ClientFilter
              filters={filters}
              setFilters={setFilters}
              values={shortValues || []}
              fetchClients={fetchClients}
            />
            <CardBody className="p-0">
              <Table
                dataSource={values}
                rowKey={record => record.id + "rowCl"}
                pagination={{
                  onChange: this.onPaginationStateChanged,
                  pageSize: pagination.per_page,
                  current: pagination.current_page,
                  total: pagination.total
                }}
                scroll={{ x: true }}
                columns={[
                  {
                    title: "ID",
                    dataIndex: "id",
                    key: "id",
                    width: "4%",
                    render: id => <Link to={"/clients/" + id}>{id}</Link>
                  },
                  {
                    title: "Business Name",
                    key: "name",
                    width: "20%",
                    render: (id, record) => (
                      <Link
                        className="nowrap-text"
                        to={"/clients/" + record.id}
                      >
                        {record.name}
                      </Link>
                    )
                  },
                  {
                    title: "Name",

                    key: "first_name",
                    width: "15%",
                    render: (text, record) => (
                      <span className="nowrap-text">
                        {`${record.first_name}  ${record.last_name}`}
                      </span>
                    )
                  },
                  {
                    title: "Manager",

                    key: "last_name",
                    width: "15%",
                    render: (_text, record) => {
                      const { managers } = record;
                      if (managers.length) {
                        return (
                          <Popover
                            content={
                              <ul>
                                {managers.map((manager, index) => (
                                  <li key={index}>{manager.name}</li>
                                ))}
                              </ul>
                            }
                          >
                            <span className="nowrap-text currency-td">
                              {record.owner.name}
                            </span>
                          </Popover>
                        );
                      }
                      return (
                        <span className="nowrap-text">{record.owner.name}</span>
                      );
                    }
                  },
                  {
                    title: "E-mail",
                    dataIndex: "email",
                    key: "email",
                    width: "20%",
                    render: text => <span className="nowrap-text">{text}</span>
                  },
                  {
                    title: "Action",
                    key: "action",
                    width: "3%",
                    render: (id, record) => (
                      <Dropdown
                        overlay={options(record)}
                        placement="bottomRight"
                        trigger={["click"]}
                      >
                        <div className="option-btn" />
                      </Dropdown>
                    )
                  }
                ]}
              />
            </CardBody>
          </Card>
        </Spin>
      </Container>
    );
  }
}
