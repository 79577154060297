import React from "react";
import moment from "moment";
import { Form, Row, Col, Select, Input, DatePicker } from "antd";
import { DATE_FORMAT } from "store/constants";
class UpperRow extends React.Component {
  selectSearch = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  renderManagerSelect = (form, current, Managers) => {
    const { getFieldDecorator } = form;
    return (
      <Row className="form__top" gutter={10}>
        <Col span={12} sm={8} lg={7}>
          <Form.Item label="Manager">
            {getFieldDecorator("manager_id", {
              rules: [{ required: false, message: "Select manager" }],
              initialValue: current.manager_id
            })(
              <Select
                id="manager"
                showSearch
                filterOption={this.selectSearch}
                loading={Managers.length === 0}
              >
                {Managers.map(el => (
                  <Select.Option key={el.id + "manager"} value={el.id}>
                    {el.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
    );
  };

  render() {
    const {
      clients,
      currencies,
      statuses,
      managers,
      taxes,
      form,
      current,
      onClientChanged,
      role
    } = this.props;

    const { currency_id } = current;
    const Managers = managers || [];
    const Clients = clients || [];
    const Currencies = currencies || [];
    const Statuses = statuses || [];
    const Taxes = taxes || [];
    const { getFieldDecorator, getFieldValue } = form;

    const dateConfig = {
      rules: [
        {
          type: "object",
          required: true,
          message: "Select time"
        }
      ]
    };

    return (
      <React.Fragment>
        {role === "admin" && this.renderManagerSelect(form, current, Managers)}
        <Row className="form__top" gutter={10}>
          <Col span={12} sm={8} lg={4}>
            <Form.Item label="To">
              {getFieldDecorator("client_id", {
                rules: [{ required: true, message: "Select client" }],
                initialValue: current.client_id
              })(
                <Select
                  id="recipient"
                  showSearch
                  filterOption={this.selectSearch}
                  loading={Clients.length === 0}
                  onChange={id => onClientChanged(id)}
                >
                  {Clients.map(el => (
                    <Select.Option key={el.id + "client"} value={el.id}>
                      {el.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col span={12} sm={8} lg={3}>
            <Form.Item label="Currency:">
              {getFieldDecorator("currency_id", {
                initialValue: currency_id
              })(
                <Select
                  id="currency_id"
                  showSearch
                  loading={Currencies.length === 0}
                >
                  {Currencies.map(el => (
                    <Select.Option key={el.id + "currency_id"} value={el.id}>
                      {el.short_name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col span={12} sm={8} lg={4}>
            <Form.Item label="Date:">
              {getFieldDecorator("issue_date", {
                ...dateConfig,
                initialValue: moment(current.issue_date)
              })(<DatePicker className="w-100" format={DATE_FORMAT} />)}
            </Form.Item>
          </Col>
          <Col span={12} sm={8} lg={4}>
            <Form.Item label="Due Date:">
              {getFieldDecorator("due_date", {
                ...dateConfig,
                initialValue: moment(current.due_date)
              })(
                <DatePicker
                  className="w-100"
                  format={DATE_FORMAT}
                  disabledDate={value =>
                    value < moment(getFieldValue("issue_date"))
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12} sm={8} lg={3}>
            <Form.Item label="Reference:">
              {getFieldDecorator("reference", {
                initialValue: current.reference
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col span={12} sm={8} md={4} lg={3}>
            <Form.Item label="Tax:">
              {getFieldDecorator("tax_id", {
                rules: [{ required: true, message: "Select tax" }],
                initialValue: current.tax_id
              })(
                <Select>
                  {Taxes.map(el => (
                    <Select.Option key={el.id + "tax"} value={el.id}>
                      {el.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col span={12} sm={8} md={4} lg={3}>
            <Form.Item label="Status:">
              {getFieldDecorator("status_id", {
                rules: [{ required: true, message: "Select status" }],
                initialValue: current.status_id
              })(
                <Select>
                  {Statuses.map(el => (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col span={12} sm={8} md={4} lg={3} className="invoice-info">
            <Form.Item label="ID:">
              {getFieldDecorator("custom_id", {
                initialValue: current.custom_id
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
export default Form.create({
  onValuesChange: (props, values) => {
    values.hasOwnProperty("tax_id") && props.countTotal(values.tax_id);
  }
})(UpperRow);
