import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import clients from './clients/reducer';
import user from './user/reducer';
import staff from './staff/reducer';
import invoices from './invoices/reducer';
const logger = createLogger({
  collapsed: true
});

export const store = createStore(
  combineReducers({ clients, user, invoices, staff }),
  composeWithDevTools(applyMiddleware(thunk, logger))
);
