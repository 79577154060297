import React from "react";
import { Select } from "antd";
import { Row, Col, Form, Button } from "shards-react";
import { history } from "helpers/history";
import { filterObjectToQueryString } from "helpers/functions";

const Option = Select.Option;

export default class ClientFilter extends React.Component {
  componentDidMount() {
    const { setFilters } = this.props;
    setFilters();
  }

  selectSearch = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  handleStateChange(_name, value) {
    const { setFilters, filters } = this.props;
    setFilters({ ...filters, id: value });
  }
  filter() {
    const { fetchClients } = this.props;
    fetchClients(0);
  }
  render() {
    const { values, filters } = this.props;
    const Clients = values || [];
    return (
      <Form>
        <Row form className="align-items-end invoice-filter">
          <Col md="3" className="form-group mb-0">
            <label className="text-muted d-block mb-2">Client:</label>
            <Select
              id="recipient"
              showSearch
              filterOption={this.selectSearch}
              onChange={id => this.handleStateChange("id", id)}
              placeholder="Select client"
              value={filters.id || undefined}
            >
              <Option key={"client-filter-default"} value={undefined}>
                -
              </Option>
              {Clients.map(el => (
                <Option key={el.id}>{el.name}</Option>
              ))}
            </Select>
          </Col>

          <Col md="auto" className="form-group mb-0">
            <Button
              outline
              theme="primary"
              className="btn_filter"
              onClick={() => {
                const { filters: params } = this.props;

                history.replace({
                  pathname: history.location.pathname,
                  search: filterObjectToQueryString(params)
                });
                this.filter();
              }}
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}
